import FileSaver from 'file-saver'

export function saveFileAsPdf(response, fileName) {
  const byteCharacters = Buffer.from(response, 'base64').toString('binary')
  let byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }

  const byteArray = new Uint8Array(byteNumbers)
  let blob = new Blob([byteArray])

  FileSaver.saveAs(blob, fileName)
  blob = null
}
